<template>
  <form v-if="useDate || useSearch" class="form-inline" @submit.prevent="doSearch">
    <template v-if="useDate">
      <label class="sr-only">Date Range</label>
      <v-date-picker class="ml-auto d-inline-flex align-items-center" v-model.range="range">
        <template v-slot="{ inputValue, inputEvents }">
          <input
            id="sf-date-range-start"
            style="width: 150px"
            class="form-control"
            :value="inputValue.start"
            v-on="inputEvents.start"
            placeholder="Date: From"
          />
          <font-awesome-icon class="mx-2" icon="arrow-right" />
          <input
            id="sf-date-range-end"
            style="width: 150px"
            class="form-control"
            :value="inputValue.end"
            v-on="inputEvents.end"
            placeholder="Date: To"
          />
        </template>
      </v-date-picker>
    </template>
    <div class="form-group ml-2" v-if="useSearch">
      <label for="search-form-q" class="sr-only">Keywords</label>
      <input
        type="text"
        class="form-control"
        name="q"
        :value="q"
        id="search-form-q"
        placeholder="Search for keywords"
        :aria-label="attributes.qAriaLabel"
        :aria-describedby="attributes.qAriaDescribedBy"
        @input="onInputChange"
      />
    </div>
    <div class="form-group ml-2">
      <button class="btn btn-dark" type="submit" :id="attributes.qAriaDescribedBySearch">
        Search
      </button>
      <!--
      <button class="btn btn-link" type="reset" :id="attributes.qAriaDescribedByReset">
        Reset
      </button>
      -->
    </div>
  </form>
</template>

<script>
import * as _ from 'lodash';
import dayjs from 'dayjs';
import 'v-calendar/dist/style.css';

export default {
  name: 'SearchForm',
  props: {
    model: {
      type: String,
      required: true,
    },
    useDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    useSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    defaultRange() {
      const start = dayjs();
      const weekFromNow = start.add(7, 'day');
      return {
        start: start.toDate(),
        end: weekFromNow.toDate(),
      };
    },
    attributes() {
      const lowerCase = this.toLower;
      const initialCaps = this.initialCaps;

      return {
        qAriaLabel: `${initialCaps} Search`,
        qAriaDescribedBySearch: `${lowerCase}-search`,
        qAriaDescribedByReset: `${lowerCase}-reset`,
      };
    },
    q() {
      return this.$store.getters['search/getKeywords'];
    },
    range: {
      get: function () {
        const storeRanges = this.$store.getters['search/getRange'];
        if (storeRanges.start === '' && storeRanges.end === '') {
          return this.defaultRange;
        } else {
          return storeRanges;
        }
      },
      set: function (range) {
        console.log('SearchForm computed range set() :range', range);
        this.$store.dispatch('search/setRange', {
          start: dayjs(range.start).startOf('day').toISOString(),
          end: dayjs(range.end).endOf('day').toISOString(),
        });
      },
    },
  },
  data() {
    return {
      initialCaps: _.capitalize(this.model),
      inputProps: {
        class: 'form-control',
        id: 'sf-date-range',
        placeholder: 'Search for a date range',
      },
      toLower: _.lowerCase(this.model),
    };
  },
  methods: {
    doSearch() {
      // console.log('SearchForm methods doSearch()', { q: this.q, range: this.range });
      this.$emit('searchQuery');
    },
    onInputChange(inputEvent) {
      // console.log('SearchForm methods onInputChange()', inputEvent.target.value);
      this.$store.dispatch('search/setKeywords', inputEvent.target.value);
    },
  },
};
</script>
